import {
  extractText,
  extractSubStepNumberFromSelector,
  extractNumberOfElements,
  extractTextContent,
} from "./utils/dom";
import { emitFunnelInteractionEvent, emitPerformanceMetric } from "./utils/custom-event";
import { getUuid } from "./utils/uuid";

import { IFunnelMetrics, IPerformanceMetrics, TableInteractionProps } from "./interfaces";

// Holds the current funnel interaction ID used for funnel workflow
let currentFunnelInteractionId: string;

export const FunnelMetrics: IFunnelMetrics = {
  funnelStart({
    funnelNameSelector,
    funnelIdentifier,
    funnelInteractionId,
    componentTheme,
    currentDocument = document,
    stepConfiguration,
    ...props
  }) {
    currentFunnelInteractionId = funnelInteractionId || getUuid();
    const funnelName = funnelIdentifier || extractTextContent(funnelNameSelector, currentDocument);
    const extractedStepConfiguration = stepConfiguration?.map(({ stepIdentifier, name, ...rest }) => ({
      name: stepIdentifier || name,
      ...rest,
    }));

    emitFunnelInteractionEvent({
      eventInteractionMetaData: {
        funnelEventType: "funnelStart",
        funnelInteractionId: currentFunnelInteractionId,
        funnelName,
        theme: componentTheme,
        stepConfiguration: extractedStepConfiguration,
        ...props,
      },
    });

    return currentFunnelInteractionId;
  },

  funnelError({ funnelInteractionId, ...props }) {
    emitFunnelInteractionEvent({
      eventInteractionMetaData: {
        funnelEventType: "funnelError",
        funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        ...props,
      },
    });
  },

  funnelComplete({ funnelInteractionId, ...props }) {
    emitFunnelInteractionEvent({
      eventInteractionMetaData: {
        funnelEventType: "funnelComplete",
        funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        ...props,
      },
    });
  },

  funnelSuccessful({ funnelInteractionId, ...props }) {
    emitFunnelInteractionEvent({
      eventInteractionMetaData: {
        funnelEventType: "funnelSuccessful",
        funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        ...props,
      },
    });
  },

  funnelCancelled({ funnelInteractionId, ...props }) {
    emitFunnelInteractionEvent({
      eventInteractionMetaData: {
        funnelEventType: "funnelCancelled",
        funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        ...props,
      },
    });
  },

  funnelChange({ funnelInteractionId, stepConfiguration, ...props }) {
    const extractedStepConfiguration = stepConfiguration?.map(({ stepIdentifier, name, ...rest }) => ({
      name: stepIdentifier || name,
      ...rest,
    }));

    emitFunnelInteractionEvent({
      eventInteractionMetaData: {
        funnelEventType: "funnelChange",
        funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        stepConfiguration: extractedStepConfiguration,
        ...props,
      },
    });
  },

  funnelStepStart({
    stepName,
    stepNameSelector,
    subStepAllSelector,
    totalSubSteps,
    funnelInteractionId,
    stepIdentifier,
    subStepConfiguration,
    currentDocument = document,
    ...props
  }) {
    const extractedStepName = stepIdentifier || stepName || extractText(stepNameSelector, currentDocument);
    const totalNoOfSubSteps = totalSubSteps || extractNumberOfElements(subStepAllSelector, currentDocument);
    const extractedStepConfiguration = subStepConfiguration?.map(({ subStepIdentifier, name, ...rest }) => ({
      name: subStepIdentifier || name,
      ...rest,
    }));

    emitFunnelInteractionEvent({
      eventInteractionMetaData: {
        funnelEventType: "funnelStepStart",
        stepName: extractedStepName,
        totalSubSteps: totalNoOfSubSteps,
        subStepConfiguration: extractedStepConfiguration,
        funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        ...props,
      },
    });
  },

  funnelStepComplete({
    stepName,
    stepNameSelector,
    subStepAllSelector,
    totalSubSteps,
    funnelInteractionId,
    stepIdentifier,
    subStepConfiguration,
    currentDocument = document,
    ...props
  }) {
    const extractedStepName = stepIdentifier || stepName || extractText(stepNameSelector, currentDocument);
    const totalNoOfSubSteps = totalSubSteps || extractNumberOfElements(subStepAllSelector, currentDocument);
    const extractedSubStepConfiguration = subStepConfiguration?.map(({ subStepIdentifier, name, ...rest }) => ({
      name: subStepIdentifier || name,
      ...rest,
    }));

    emitFunnelInteractionEvent({
      eventInteractionMetaData: {
        funnelEventType: "funnelStepComplete",
        totalSubSteps: totalNoOfSubSteps,
        stepName: extractedStepName,
        subStepConfiguration: extractedSubStepConfiguration,
        funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        ...props,
      },
    });
  },

  funnelStepError({
    stepName,
    stepNameSelector,
    stepErrorSelector,
    funnelInteractionId,
    stepIdentifier,
    stepErrorContext,
    subStepConfiguration,
    currentDocument = document,
    ...props
  }) {
    const extractedStepName = stepIdentifier || stepName || extractText(stepNameSelector, currentDocument);
    const extractedStepError = stepErrorContext || extractText(stepErrorSelector, currentDocument);
    const extractedSubStepConfiguration = subStepConfiguration?.map(({ subStepIdentifier, name, ...rest }) => ({
      name: subStepIdentifier || name,
      ...rest,
    }));

    emitFunnelInteractionEvent({
      eventInteractionMetaData: {
        funnelEventType: "funnelStepError",
        stepError: extractedStepError,
        stepName: extractedStepName,
        subStepConfiguration: extractedSubStepConfiguration,
        funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        ...props,
      },
    });
  },

  funnelStepNavigation({
    stepNumber,
    stepNameSelector,
    stepName,
    subStepAllSelector,
    totalSubSteps,
    funnelInteractionId,
    stepIdentifier,
    subStepConfiguration,
    currentDocument = document,
    ...props
  }) {
    const extractedStepName = stepIdentifier || stepName || extractText(stepNameSelector, currentDocument);
    const totalNoOfSubSteps = totalSubSteps || extractNumberOfElements(subStepAllSelector, currentDocument);
    const extractedSubStepConfiguration = subStepConfiguration?.map(({ subStepIdentifier, name, ...rest }) => ({
      name: subStepIdentifier || name,
      ...rest,
    }));

    emitFunnelInteractionEvent({
      eventInteractionMetaData: {
        funnelEventType: "funnelStepNavigation",
        originStepNumber: stepNumber,
        originStepName: extractedStepName,
        totalSubSteps: totalNoOfSubSteps,
        subStepConfiguration: extractedSubStepConfiguration,
        funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        ...props,
      },
    });
  },

  funnelStepChange({
    stepName,
    stepNameSelector,
    subStepAllSelector = "",
    totalSubSteps,
    funnelInteractionId,
    stepIdentifier,
    subStepConfiguration,
    currentDocument = document,
    ...props
  }) {
    const extractedStepName = stepIdentifier || stepName || extractText(stepNameSelector, currentDocument);
    const totalNoOfSubSteps = totalSubSteps || extractNumberOfElements(subStepAllSelector, currentDocument);
    const extractedSubStepConfiguration = subStepConfiguration?.map(({ subStepIdentifier, name, ...rest }) => ({
      name: subStepIdentifier || name,
      ...rest,
    }));

    emitFunnelInteractionEvent({
      eventInteractionMetaData: {
        funnelEventType: "funnelStepChange",
        stepName: extractedStepName,
        totalSubSteps: totalNoOfSubSteps,
        subStepConfiguration: extractedSubStepConfiguration,
        funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        ...props,
      },
    });
  },

  funnelSubStepStart({
    subStepNameSelector,
    stepNameSelector,
    stepName,
    subStepNumber,
    subStepName,
    subStepSelector,
    subStepAllSelector,
    funnelInteractionId,
    stepIdentifier,
    subStepIdentifier,
    subStepConfiguration,
    currentDocument = document,
    ...props
  }) {
    const extractedSubStepName = subStepIdentifier || subStepName || extractText(subStepNameSelector, currentDocument);
    const extractedStepName = stepIdentifier || stepName || extractText(stepNameSelector, currentDocument);
    const extractedSubStepNumber = subStepNumber || extractSubStepNumberFromSelector(
      subStepSelector,
      subStepAllSelector,
      currentDocument,
    );
    const extractedSubStepConfiguration = subStepConfiguration?.map(({ subStepIdentifier, name, ...rest }) => ({
      name: subStepIdentifier || name,
      ...rest,
    }));

    emitFunnelInteractionEvent({
      eventInteractionMetaData: {
        funnelEventType: "funnelSubStepStart",
        subStepName: extractedSubStepName,
        subStepNumber: extractedSubStepNumber,
        stepName: extractedStepName,
        subStepConfiguration: extractedSubStepConfiguration,
        funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        ...props,
      },
    });
  },

  funnelSubStepComplete({
    subStepNumber,
    subStepNameSelector,
    subStepName,
    subStepSelector,
    stepNameSelector,
    stepName,
    subStepAllSelector,
    funnelInteractionId,
    stepIdentifier,
    subStepIdentifier,
    subStepConfiguration,
    currentDocument = document,
    ...props
  }) {
    const extractedSubStepName = subStepIdentifier || subStepName || extractText(subStepNameSelector, currentDocument);
    const extractedStepName = stepIdentifier || stepName || extractText(stepNameSelector, currentDocument);
    const extractedSubstepNumber = subStepNumber || extractSubStepNumberFromSelector(
      subStepSelector,
      subStepAllSelector,
      currentDocument,
    );
    const extractedSubStepConfiguration = subStepConfiguration?.map(({ subStepIdentifier, name, ...rest }) => ({
      name: subStepIdentifier || name,
      ...rest,
    }));

    emitFunnelInteractionEvent({
      eventInteractionMetaData: {
        funnelEventType: "funnelSubStepComplete",
        subStepName: extractedSubStepName,
        subStepNumber: extractedSubstepNumber,
        stepName: extractedStepName,
        subStepConfiguration: extractedSubStepConfiguration,
        funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        ...props,
      },
    });
  },

  funnelSubStepError({
    fieldIdentifier,
    fieldErrorContext,
    fieldLabelSelector,
    fieldErrorSelector,
    subStepNumber,
    subStepSelector,
    subStepNameSelector,
    subStepName,
    stepNameSelector,
    stepName,
    subStepAllSelector,
    funnelInteractionId,
    stepIdentifier,
    subStepIdentifier,
    subStepConfiguration,
    currentDocument = document,
    ...props
  }) {
    const fieldName = fieldIdentifier || extractText(fieldLabelSelector, currentDocument);
    const fieldError = fieldErrorContext || extractText(fieldErrorSelector, currentDocument);
    const extractedSubStepName = subStepIdentifier || subStepName || extractText(subStepNameSelector, currentDocument);
    const extractedStepName = stepIdentifier || stepName || extractText(stepNameSelector, currentDocument);
    const extractedSubStepNumber = subStepNumber || extractSubStepNumberFromSelector(
      subStepSelector,
      subStepAllSelector,
      currentDocument,
    );
    const extractedSubStepConfiguration = subStepConfiguration?.map(({ subStepIdentifier, name, ...rest }) => ({
      name: subStepIdentifier || name,
      ...rest,
    }));

    emitFunnelInteractionEvent({
      eventInteractionMetaData: {
        funnelEventType: "funnelSubStepError",
        subStepName: extractedSubStepName,
        subStepNumber: extractedSubStepNumber,
        stepName: extractedStepName,
        fieldName,
        fieldError,
        subStepConfiguration: extractedSubStepConfiguration,
        funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        ...props,
      },
    });
  },

  helpPanelInteracted({
    elementSelector,
    stepNameSelector,
    stepName,
    subStepNumber,
    subStepSelector,
    subStepNameSelector,
    subStepName,
    subStepAllSelector,
    funnelInteractionId,
    stepIdentifier,
    subStepIdentifier,
    subStepConfiguration,
    currentDocument = document,
    ...props
  }) {
    const extractedHelpContent = extractTextContent(elementSelector, currentDocument);
    const extractedSubStepName = subStepIdentifier || subStepName || extractText(subStepNameSelector, currentDocument);
    const extractedStepName = stepIdentifier || stepName || extractText(stepNameSelector, currentDocument);
    const extractedSubStepNumber = subStepNumber || extractSubStepNumberFromSelector(
      subStepSelector,
      subStepAllSelector,
      currentDocument,
    );
    const extractedSubStepConfiguration = subStepConfiguration?.map(({ subStepIdentifier, name, ...rest }) => ({
      name: subStepIdentifier || name,
      ...rest,
    }));

    emitFunnelInteractionEvent({
      eventInteractionMetaData: {
        funnelEventType: "helpPanelInteracted",
        stepName: extractedStepName,
        subStepNumber: extractedSubStepNumber,
        subStepName: extractedSubStepName,
        helpContent: extractedHelpContent,
        subStepConfiguration: extractedSubStepConfiguration,
        funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        ...props,
      },
    });
  },

  externalLinkInteracted({
    elementSelector,
    stepNameSelector,
    stepName,
    subStepNumber,
    subStepSelector,
    subStepNameSelector,
    subStepName,
    subStepAllSelector,
    funnelInteractionId,
    stepIdentifier,
    subStepIdentifier,
    subStepConfiguration,
    currentDocument = document,
    ...props
  }) {
    const extractedLinkText = extractTextContent(elementSelector, currentDocument);
    const extractedSubStepName = subStepIdentifier || subStepName || extractText(subStepNameSelector, currentDocument);
    const extractedStepName = stepIdentifier || stepName || extractText(stepNameSelector, currentDocument);
    const extractedSubStepNumber = subStepNumber || extractSubStepNumberFromSelector(
      subStepSelector,
      subStepAllSelector,
      currentDocument,
    );
    const extractedSubStepConfiguration = subStepConfiguration?.map(({ subStepIdentifier, name, ...rest }) => ({
      name: subStepIdentifier || name,
      ...rest,
    }));

    emitFunnelInteractionEvent({
      eventInteractionMetaData: {
        funnelEventType: "externalLinkInteracted",
        stepName: extractedStepName,
        subStepNumber: extractedSubStepNumber,
        subStepName: extractedSubStepName,
        linkText: extractedLinkText,
        subStepConfiguration: extractedSubStepConfiguration,
        funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        ...props,
      },
    });
  },
};

/**
 * Method to emit client side performance metrics from Cloudscape components
 */
export const PerformanceMetrics: IPerformanceMetrics = {
  /**
   * Method to emit Cloudscape table interaction latencies
   * @param {TableInteractionProps} param0
   */
  tableInteraction({
    interactionTime,
    userAction,
    instanceIdentifier = "",
    componentIdentifier = "",
    noOfResourcesInTable = 0,
    interactionMetadata = "",
  }: TableInteractionProps) {
    if (interactionTime && interactionTime > 0) {
      emitPerformanceMetric({
        operationalData: {
          metricList: [
            {
              metricName: "tableInteraction",
              metricValue: interactionTime,
              metricUnit: "Milliseconds",
              metricDetails: JSON.stringify({
                userAction,
                instanceIdentifier,
                componentIdentifier,
                noOfResourcesInTable,
                interactionMetadata,
              }),
            },
          ],
        },
        timestamp: Date.now(),
      });
    }
  },
};
