
      const customCSSPropertiesMap: Record<string,string> = {
        "breadcrumbsGap": "--awsui-breadcrumbs-gap-tkr9yl",
"contentGapLeft": "--awsui-content-gap-left-tkr9yl",
"contentGapRight": "--awsui-content-gap-right-tkr9yl",
"contentHeight": "--awsui-content-height-tkr9yl",
"defaultMaxContentWidth": "--awsui-default-max-content-width-tkr9yl",
"defaultMinContentWidth": "--awsui-default-min-content-width-tkr9yl",
"drawerSize": "--awsui-drawer-size-tkr9yl",
"footerHeight": "--awsui-footer-height-tkr9yl",
"headerGap": "--awsui-header-gap-tkr9yl",
"headerHeight": "--awsui-header-height-tkr9yl",
"layoutWidth": "--awsui-layout-width-tkr9yl",
"mainGap": "--awsui-main-gap-tkr9yl",
"mainOffsetLeft": "--awsui-main-offset-left-tkr9yl",
"mainTemplateRows": "--awsui-main-template-rows-tkr9yl",
"maxContentWidth": "--awsui-max-content-width-tkr9yl",
"minContentWidth": "--awsui-min-content-width-tkr9yl",
"mobileBarHeight": "--awsui-mobile-bar-height-tkr9yl",
"notificationsGap": "--awsui-notifications-gap-tkr9yl",
"notificationsHeight": "--awsui-notifications-height-tkr9yl",
"offsetTop": "--awsui-offset-top-tkr9yl",
"overlapHeight": "--awsui-overlap-height-tkr9yl",
"navigationWidth": "--awsui-navigation-width-tkr9yl",
"splitPanelReportedHeaderSize": "--awsui-split-panel-reported-header-size-tkr9yl",
"splitPanelReportedSize": "--awsui-split-panel-reported-size-tkr9yl",
"splitPanelHeight": "--awsui-split-panel-height-tkr9yl",
"splitPanelMinWidth": "--awsui-split-panel-min-width-tkr9yl",
"splitPanelMaxWidth": "--awsui-split-panel-max-width-tkr9yl",
"toolsMaxWidth": "--awsui-tools-max-width-tkr9yl",
"toolsWidth": "--awsui-tools-width-tkr9yl",
"toolsAnimationStartingOpacity": "--awsui-tools-animation-starting-opacity-tkr9yl",
"contentScrollMargin": "--awsui-content-scroll-margin-tkr9yl",
"flashbarStackDepth": "--awsui-flashbar-stack-depth-tkr9yl",
"flashbarStackIndex": "--awsui-flashbar-stack-index-tkr9yl",
"flashbarStickyBottomMargin": "--awsui-flashbar-sticky-bottom-margin-tkr9yl",
"stackedNotificationsBottomMargin": "--awsui-stacked-notifications-bottom-margin-tkr9yl",
"stackedNotificationsDefaultBottomMargin": "--awsui-stacked-notifications-default-bottom-margin-tkr9yl",
"dropdownDefaultMaxWidth": "--awsui-dropdown-default-max-width-tkr9yl",
"spinnerRotatorFrom": "--awsui-spinner-rotator-from-tkr9yl",
"spinnerRotatorTo": "--awsui-spinner-rotator-to-tkr9yl",
"spinnerLineLeftFrom": "--awsui-spinner-line-left-from-tkr9yl",
"spinnerLineLeftTo": "--awsui-spinner-line-left-to-tkr9yl",
"spinnerLineRightFrom": "--awsui-spinner-line-right-from-tkr9yl",
"spinnerLineRightTo": "--awsui-spinner-line-right-to-tkr9yl",
"sliderLabelCount": "--awsui-slider-label-count-tkr9yl",
"sliderTickCount": "--awsui-slider-tick-count-tkr9yl",
"sliderReferenceColumn": "--awsui-slider-reference-column-tkr9yl",
"sliderNextReferenceColumn": "--awsui-slider-next-reference-column-tkr9yl",
"sliderMaxStart": "--awsui-slider-max-start-tkr9yl",
"sliderMinEnd": "--awsui-slider-min-end-tkr9yl",
"sliderRangeInlineSize": "--awsui-slider-range-inline-size-tkr9yl",
"sliderTooltipPosition": "--awsui-slider-tooltip-position-tkr9yl",
      };
      export default customCSSPropertiesMap;
    